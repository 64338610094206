<template>
  <div class="home">
    <div class="text" :style="leftPosition">
      <h2>Fast Taylor Transform</h2>
      <button>Learn more</button>
      <i class="fas fa-chevron-down" :style="{'margin-top': `${arrowTop}px`}"></i>
    </div>
    <img class="bg-img" src="../assets/knickbusch-bg.png" alt="">
  </div>
</template>

<script>

export default {
  name: 'Home',
  props: ['isMobile'],
  data () {
    return {
      arrowTop: 30,
      leftPosition: {left: `0px`},
    }
  },
  mounted() {
    this.setLeftPosition();
    window.addEventListener('resize', this.setLeftPosition)
    setInterval(() => {
      this.arrowTop = this.arrowTop === 30 ? 40: 30;
    }, 500)
  },
  methods: {
    setLeftPosition() {
      const width = window.innerWidth;
      const left = (width - 405) / 2;
      this.leftPosition = {left: `${left}px`};
    }
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.setLeftPosition)
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
.home {
  width: 100%;
  .bg-img {
    width: 100%;
    opacity: .9;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 2;
    width: 405px;
    text-align: center;
    h2 {
      font-family: 'Abel', sans-serif;
      color: #fff;
      font-size: 6em;
      margin: 1.3em 0 0.2em;
    }
    button {
      background: transparent;
      border: 2px solid white;
      color: white;
      border-radius: 5px;
      font-size: 20px;
      padding: 13px 28px;
      width: 168px;
      margin: 0 auto;
      transition: 250ms ease all;
      &:hover {
        background: #fff;
        color: $primary-color;
      }
    }
    i {
      color: white;
      margin-top: 30px;
      font-size: 2em;
      transition: 700ms ease all;
    }
  }
}

@media only screen and (max-width: 830px) {
  .home {
    .bg-img {
      width: 130%;
    }
    .text {

      h2 {
        font-size: 5em;
        margin-top: 1.9em;
      }
    }
  }
}
@media only screen and (max-width: 685px) {
  .home .text  h2{
    font-size: 4em;
  }
}

@media only screen and (max-width: 450px) {
  .home {
    .bg-img {
      width: 180%;
    }
    .text  h2{
      font-size: 3.4em;
      margin-top: 1.9em;
    }
  }
}
</style>
